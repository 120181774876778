<div class="blocks_detail">
    <div class="blocks_detail__first_row">
        <div class="block_detail">
            <h2>Social</h2>
            <div class="block_detail__box">
                <div class="block_detail__box__rightside">
                    <h4>1 Day Ago</h4>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
                    </p>
                    <div class="social_media">
                        <div class="social_media__element">
                            <img src="/assets/images/facebook.png">
                            <p>Become a fan</p>
                        </div>
                        <div class="social_media__element">
                            <img src="/assets/images/twitter.png">
                            <p>Become a fan</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block_detail">
            <h2>Latest Events</h2>
            <div class="block_detail__box">
                <div class="block_detail__box__lefside">
                    <h1>08</h1>
                    <h4>JUNE</h4>
                </div>
                <div class="block_detail__box__rightside">
                    <h4>8:00 am</h4>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                </div>
            </div>
        </div>
        <div class="block_detail">
            <h2>More Event</h2>
            <div class="block_detail__box">
                <div class="block_detail__box__lefside">
                    <h1>19</h1>
                    <h4>MAY</h4>
                </div>
                <div class="block_detail__box__rightside" style="border: none;">
                    <h4>8:00 am</h4>
                    <p>
                        Lorem Ipsum is simply dummy text.
                    </p>
                </div>
            </div>
        </div>
        <div class="block_detail">
            <img src="/assets/images/1-3.jpg">
        </div>
    </div>
    <div class="blocks_detail__second_row">

    </div>
</div>