import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-cards-swiper',
  templateUrl: './cards-swiper.component.html',
  styleUrls: ['./cards-swiper.component.css']
})
export class CardsSwiperComponent implements OnInit {

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 3,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false
  };
  constructor() { }

  ngOnInit(): void {
  }

}
