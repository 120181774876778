import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-picture-advertisement',
  templateUrl: './big-picture-advertisement.component.html',
  styleUrls: ['./big-picture-advertisement.component.css']
})
export class BigPictureAdvertisementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
