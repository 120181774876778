<div class="storyOfSuccess">
    <div class="bg-image">
        <img src="/assets/images/bodclapslide_orig.jpg">
    </div>
    <div class="content">
        <h1>
            <span class="light">WHY</span><br/>
            <!-- <span class="light"></span><br/> -->
            <span class="bold">CIA First?</span>
        </h1>
        <div class="content-details">
            <div class="content-details__title">
                <h3>Dr. Mark Zuckerburg</h3>
            </div>
            <div class="content-details__contents">
                <p>
                    CIA FIRST International School  is one of only four schools in Cambodia that holds the international WASC Accreditation 
                    from K3-12 and CIA FIRST's graduating diploma is recognised by the Ministry of Education, Youth and Sport as equivalent 
                    to the national high school diploma. CIA FIRST’s renowned and demanding curriculum ensures your child will thrive at the 
                    next level, whether that is in Cambodia or overseas higher education.<br><br>

                    Our students are dynamic, motivated and diverse global citizens.
                </p>
            </div>
            <div class="content-details__readmore">
                <p>Read Full Story</p>
            </div>
        </div>
    </div>
</div>