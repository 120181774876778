import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blocks-detail',
  templateUrl: './blocks-detail.component.html',
  styleUrls: ['./blocks-detail.component.css']
})
export class BlocksDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
