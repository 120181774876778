<div class="campus">
    <div class="bg-image">
        <img src="/assets/images/photo_2020-07-06_10-09-13.jpg">
    </div>
    <div class="campus_content">
        <div class="campus_content_title">
            <span class="light">CAMPUS</span><br>
            <span class="bold">LIFE</span><br>
        </div>
        
        <div class="for-cards-swiper">
            <app-cards-swiper></app-cards-swiper>
        </div>
    </div>
</div>
