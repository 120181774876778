<header id="header">
    <div class="header_container">

        <div class="header_image">
            <img src="/assets/images/logo-cia-first-png.png" id="header_image">
        </div>

        <div class="header_nav">
            <div class="header_navbars">

                <div class="top_navbar">
                    <ul>
                        <li><a href="/" class="top_navbar__menuItem">CIA First Library Catalog</a></li>
                        <li><a href="/" class="top_navbar__menuItem">SchoolBase Login</a></li>
                        <li><a href="/" class="top_navbar__menuItem">CIA First English Center</a></li>
                    </ul>
                </div>

                <div class="bottom_navbar">
                    <ul>
                        <li>
                            <a href="/" class="bottom_navbar__menuItem">Home</a>
                            <ul>
                                <li><a href="/" class="bottom_navbar__menuItem">Our School</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">Why CIA First</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">Vision and Mission</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">Accrediations</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">Facilities</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">Academics</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="/" class="bottom_navbar__menuItem">Enrolment</a>
                            <ul>
                                <li><a href="/" class="bottom_navbar__menuItem">School Fees</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">Payment Methods</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="/" className="bottom_navbar__menuItem">Online Learning</a>
                            <ul>
                                <li><a href="/" class="bottom_navbar__menuItem">Overview</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">Class Schedules</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="/" class="bottom_navbar__menuItem">Community</a>
                            <ul>
                                <li><a href="/" class="bottom_navbar__menuItem">School Blog & Updates</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">Principals</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">PAPA</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">SchoolBase</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">CIA First English Center</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="/" class="bottom_navbar__menuItem">Calendar</a>
                        </li>
                        <li>
                            <a href="/" class="bottom_navbar__menuItem">Gallery</a>
                            <ul>
                                <li><a href="/" class="bottom_navbar__menuItem">2020 Graduation Photos</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">G12 Class Photos</a></li>
                                <li><a href="/" class="bottom_navbar__menuItem">School Year 19/20</a></li>
                            </ul>
                        </li>
                        <li><a href="/" class="bottom_navbar__menuItem">Work With Us</a></li>
                        <li><a href="/" class="bottom_navbar__menuItem">Contact Us</a></li>
                    </ul>
                </div>

            </div>

            <div class="header_search">
                <img src="/assets/images/icons8-search-64.png">
            </div>
        </div>
    </div>
</header>