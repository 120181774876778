<div class="WeAreSocial">
    <div class="columns">
        <div class="first-column">
            <span class="light">WE</span><br/>
            <span class="light">ARE</span><br/>
            <span class="bold">SOCIAL</span>
        </div>
        <div class="second-column" style="width: 45%;">
            <h2>American University in Phnom Penh, Cambodia - Prospective Students</h2>
            <div class="content">
                <h4>1 day ago</h4>
                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
            </div>
            <div class="content">
                <h4>1 day ago</h4>
                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
            </div>
        </div>
        <div class="third-column" style="width: 25%;">
            <button style="background-color: #0294CD;">
                <img src="/assets/images/twitter-white.png">
                <p>Follow Us on Twitter</p>
            </button>

            <button style="background-color: #00477F;">
                <img src="/assets/images/facebook-white.png">
                <p>Become a Fan</p>
            </button>
        </div>
    </div>
</div>