import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-component',
  templateUrl: './header-component.component.html',
  styleUrls: ['./header-component.component.css']
})
export class HeaderComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    


  }
  title = 'cia-first-school';

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.querySelector('#header');
    let element2 = document.querySelector('#header_image');
    let hyperlinks = document.getElementsByTagName('a');
    
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
      element2.setAttribute('src', '/assets/images/logo-cia-first-png-black.png');
    } else {
      element.classList.remove('navbar-inverse');
      element2.setAttribute('src', '/assets/images/logo-cia-first-png.png');
    }
  }
}
