<div class="footer">
    <div class="footer-container">
        <div class="first-row">
            <div class="first-column">
                <img src="/assets/images/logo-cia-first-png.png">
                <div>
                    <p>1 Georgi Izmidiev Sq.</p>
                    <p>First Floor. Room 2</p>
                    <p>Blagoevgrad 2700, Cambodia</p>
                    <p>+359(0) 73 888 306</p>
                </div>
            </div>
            <div class="second-column" style="width: 45%;">
                <div class="first">
                    <a href="#">Discover AUBG</a>
                    <a href="#">Academics</a>
                    <a href="#">Campus Life</a>
                    <a href="#">Admissions</a>
                    <a href="#">Resources</a>
                    <a href="#">Events</a>
                    <a href="#">News</a>
                    <a href="#">Contact Us</a>
                </div>
                <div class="second">
                    <a href="#">Apply</a>
                    <a href="#">Faculty</a>
                    <a href="#">Students</a>
                    <a href="#">Alumni</a>
                    <a href="#">Visitors</a>
                </div>
                <div class="third">
                    <a href="#">Administration</a>
                    <a href="#">Directory</a>
                    <a href="#">A-Z Index</a>
                    <a href="#">Sitemap</a>
                </div>
                <div class="fouth">
                    <a href="#">Jobs at AUBG</a>
                    <a href="#">Career Center</a>
                    <a href="#">Library</a>
                    <a href="#">Events & Activities</a>
                    <a href="#">Health & Wellness</a>
                    <a href="#">Web Mail</a>
                </div>
            </div>
            <div class="third-column" style="width: 25%;">
                <h3>Sign Up for Over Newsletter</h3>
                <form>
                    <input type="text" placeholder="Email Address">
                    <button>Subscribe</button>
                </form>
                <h3>Follow Us</h3>
                <div class="btnGroupSocial">
                    <a href="https://www.facebook.com/"><img src="/assets/images/facebook-white.png"></a>
                    <a href="https://twitter.com/"><img src="/assets/images/twitter-white.png"></a>
                    <a href="https://auxswot.com/"><img src="/assets/images/google-plus-white.png"></a>
                </div>
            </div>
        </div>
        <div class="second-row">
            <p>Copyright 2020. All Right Reserved. American University in Cambodia</p>
            <p>By Auxswot</p>
        </div>
    </div>
</div>