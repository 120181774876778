<div class="big-picture-advertisement">
    <div class="bg-image">
        <img src="/assets/images/1-6.jpg">
    </div>
    <div class="advertisement-content">
        <div class="advertisement-content_text">
            <span class="light">LIVE</span>
            <span class="bold">LEARN</span>
            <span class="light">CREATE</span>
        </div>
        <div class="advertisement-content_boxes">
            <div class="advertisement-content_box">
                <img src="/assets/images/big-picture-advertisement-image1.jpg" />
                <h1>Enquire Now</h1>
                <p>Learn about literal arts</p>
            </div>
            <div class="advertisement-content_box">
                <img src="/assets/images/big-picture-advertisement-image2.jpg" />
                <h1>Schedule a Tour</h1>
                <p>See what our students are doing</p>
            </div>
            <div class="advertisement-content_box">
                <img src="/assets/images/big-picture-advertisement-image3.jpg" />
                <h1>Call Us Today ...</h1>
                <p>Bill Gates ...</p>
            </div>
        </div>
    </div>
</div>