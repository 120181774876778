<swiper [config]="config" class="slide">
    <div>
        <div class="each_content">
            <div class="for-image">
                <img src="/assets/images/1-1.jpg">
            </div>
            <h1>Fooball Field</h1>
            <h4>
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using.
            </h4>
            <p>Learn More</p>
        </div>
    </div>
    <div>
        <div class="each_content">
            <div class="for-image">
                <img src="/assets/images/1-15.jpg">
            </div>
            <h1>Basketball Court</h1>
            <h4>
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
            </h4>
            <p>Learn More</p>
        </div>
    </div>
    <div>
        <div class="each_content">
            <div class="for-image">
                <img src="/assets/images/1-2-1.jpg">
            </div>
            <h1>Swimming Pool</h1>
            <h4>
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
            </h4>
            <p>Learn More</p>
        </div>
    </div>
    <div>
        <div class="each_content">
            <div class="for-image">
                <img src="/assets/images/1-15.jpg">
            </div>
            <h1>Basketball Court</h1>
            <h4>
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
            </h4>
            <p>Learn More</p>
        </div>
    </div>
    <div>
        <div class="each_content">
            <div class="for-image">
                <img src="/assets/images/1-2-1.jpg">
            </div>
            <h1>Swimming Pool</h1>
            <h4>
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
            </h4>
            <p>Learn More</p>
        </div>
    </div>
</swiper>