<div class="big-block-details">
    <div class="big-block-details__container">
        <div class="big-block-details__leftside">
            <div class="details__leftside__title">
                <span class="title_one">Our</span><br/>
                <span class="title_three">Campus</span>
            </div>
            <div class="details__leftside__content">
                <div class="first-box">
                    <h4>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the 
                        industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the 
                        leap into electronic typesetting, remaining essentially unchanged.
                    </h4>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                </div>
                <div class="second-box">
                    <div class="for-image">
                        <img src="/assets/images/big-picture-advertisement-image1.jpg">
                    </div>
                    <h1>CIA First Internation School</h1>
                    <h3>No. 107, Street 2004 (International School Road)</h3>
                    <h3>(Opposite Northbridge Community)</h3>
                    <h3>Phnom Penh, Kingdom of Cambodia</h3>
                </div>
                <div class="third-box">
                    <div class="for-image">
                        <img src="/assets/images/big-picture-advertisement-image2.jpg">
                    </div>
                    <h1>CIA PP International School</h1>
                    <h3>No. 107, Street 2004 (International School Road)</h3>
                    <h3>(Opposite Northbridge Community)</h3>
                    <h3>Phnom Penh, Kingdom of Cambodia</h3>
                </div>
            </div>
        </div>
    </div>
</div>