import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { AppComponent } from './app.component';
import { HeaderComponentComponent } from './header-component/header-component.component';
import { BigPictureAdvertisementComponent } from './big-picture-advertisement/big-picture-advertisement.component';
import { BlocksDetailComponent } from './blocks-detail/blocks-detail.component';
import { BigBlockDetailsComponent } from './big-block-details/big-block-details.component';
import { CampusComponent } from './campus/campus.component';
import { StoryOfSuccessComponent } from './story-of-success/story-of-success.component';
import { WearesocialComponent } from './wearesocial/wearesocial.component';
import { FooterComponent } from './footer/footer.component';
import { CardsSwiperComponent } from './cards-swiper/cards-swiper.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponentComponent,
    BigPictureAdvertisementComponent,
    BlocksDetailComponent,
    BigBlockDetailsComponent,
    CampusComponent,
    StoryOfSuccessComponent,
    WearesocialComponent,
    FooterComponent,
    CardsSwiperComponent
  ],
  imports: [
    BrowserModule,
    SwiperModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
